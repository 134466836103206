import React from "react";


export default function RevealLink({ uuid, children, className=null }) {
  return (
    <a
      className={className}
      rel="nofollow"
      href={`https://www.ksiegiwieczyste.pl/previews/new?preview%5Bguuid%5D=${uuid}`}
    >
      {children}
    </a>
  );
}
