import React from "react";

import Banner, {BANNER_STYLE} from "../Banner/Banner";

export default function StatusWelcomeCity() {
  return (
    <Banner style={BANNER_STYLE.NEUTRAL} title="Krótko o wyszukiwarce wg. adresu...">
      <b>Jak rozpocząć:</b>
      <ul>
        <li>Zacznij pisać nazwę miejscowości, a następnie <b>wybierz z listy</b> propozycji.</li>
        <li>Możesz podać numer budynku, jeśli ulica zawiera ich wiele</li>
        <li>Dla bardzo małych miejscowości, które nie posiadają ulic, nie musisz podawać nazwy ulicy.</li>
      </ul>
    </Banner>
  )
}