import { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import * as APIConnector from "../components/APIConnector";




export default function useQuerySearch(paramsConstructorFunc, apiMethodName) {
  const { initiateAPISearch } = useContext(APIConnector.APIConnectorContext);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  useEffect(() => {
    const searchHash = urlSearchParams.get("search");
    if (!searchHash) return;

    const searchParams = paramsConstructorFunc(searchHash);

    initiateAPISearch({
      query: apiMethodName,
      params: searchParams,
    });
  }, []);
}
