import React from "react";
import Banner, {BANNER_STYLE} from "../Banner/Banner";

export default function StatusNoResults({title, children}) {

  return (
    <Banner style={BANNER_STYLE.WARNING} title={title}>
      {children}
    </Banner>
  );
}
