import { Buffer } from "buffer";



function searchParamsObj(citiesId, lotNumber) {
  return {citiesId, lotNumber};
}



function constructSearchParamsFromHash(hash) {
  const arr = JSON.parse(Buffer.from(hash, "base64").toString("utf8"));
  return searchParamsObj(arr[0],arr[1]);
}

export { searchParamsObj, constructSearchParamsFromHash };
