import React from "react";

import Toggle from "../Toggle/index";
import ResultCityUnit from "./CityResultUnit";
import RevealLink from "./Assets/RevealLink";
import ShowHideUnitsButton from "./Assets/ShowHideUnitsButton";


import { FiBook } from "react-icons/fi";
import IconicStats from "./IconicStats";



export default function CityResultAggregate({ data, cityInfo = null }) {
  return (
    <Toggle>
      
      <div id={data.uuid} className="searchResult--grid pt-2 pb-2 border-top">
        <div className="searchResult--iconicMeta pb-2">
          <IconicStats stats={data.stat} />
        </div>

        <div className="searchResult--address">
          {data.info.street} {data.info.number}, {data.info.city}

          {cityInfo && <span className="searchResult--voivodeship">, woj: {cityInfo.descriptor.voivodeship}</span>}
        </div>


        <Toggle.Button>
          <ShowHideUnitsButton count={data.children.length} uuid={data.uuid}/>
        </Toggle.Button>

        <div className="searchResult--ctaColumn">
        <RevealLink uuid={data.uuid}>
          <button className="searchResult--kwNumber">
            <FiBook />
            &nbsp;{data.canonical}
          </button>
        </RevealLink>
        </div>
      </div>

      <Toggle.On>
        {data.children.length === 0 ? null : (
          <div className="searchResult--subresults">
            {data.children.map((e, i) => (
              <ResultCityUnit key={i} data={e} />
            ))}
          </div>
        )}
      </Toggle.On>
    </Toggle>
  );
}
