import React from "react";
import * as APIClient from "../comms/APIClient";

export const APIConnectorContext = React.createContext();

const initialState = {
  loading: false,
  done: false,
  error: null,
  data: null,
  queryAndParams: null
}


const reducer = (connState, action) => {
  switch( action.type ) {
    case 'FETCH_START':
      return { ...connState, loading: true}
    case 'FETCH_SUCCESS':
      return { ...connState, loading: false, done: true, data: action.payload }
    case 'FETCH_ERROR':
      return { ...connState, loading: false, error: action.payload }
    case 'NEW_CALL': 
      return { ...initialState, queryAndParams: action.payload }
    default:
      return connState;
  }
}


export default function APIConnector( {children} ) {
  let [connState, dispatch] = React.useReducer( reducer, initialState );
  console.log("STATE:", connState );


  React.useEffect( () =>{


    const fetchData = async() => {
      if( connState.queryAndParams === null ) return; 

      dispatch( {type: 'FETCH_START'});

      APIClient.call( connState.queryAndParams.query, connState.queryAndParams.params )
        .then( (response) => {
          console.log("API success", response);
          return dispatch( {type: 'FETCH_SUCCESS', payload: response});
        })
        .catch( (error) => {
          return dispatch( {type: 'FETCH_ERROR', payload: error});
        });
    }


    fetchData();
  }, [connState.queryAndParams])


  const initiateAPISearch = (qAndP) => {
    dispatch( {type: 'NEW_CALL', payload: qAndP} );
  }



  return (
    <APIConnectorContext.Provider value={ {connState, initiateAPISearch}  }>
      {children}
    </APIConnectorContext.Provider>
  )
}