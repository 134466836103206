import "@algolia/autocomplete-theme-classic/dist/theme.css"; 
//here it needs to be directly to css file otherwise error, workaroud described on the internet

import React from "react";
import { Routes, Route, BrowserRouter, Navigate, useNavigate, useSearchParams } from "react-router-dom";

import "./App.css";
import "./css/pages-searchboxes.css"
import Layout from "./components/Pages/Layout";
import SearchCityPage from "./components/Pages/SearchCityPage";
import SearchLotPage from "./components/Pages/SearchLotPage";
import SearchPrecinctPage from "./components/Pages/SearchPrecinctPage";
import APIConnector from "./components/APIConnector";


function postSearchFunction() {

    const theContent = document.getElementById("theContent");
    theContent?.remove();

}


function App({ onAppLoaded }) {

  React.useEffect(() => {
    onAppLoaded && onAppLoaded();
  }, [onAppLoaded]);

  return (
    <APIConnector>
      <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/index.html" element={<Navigate to="/search/city" />} />
          <Route path="/" exact element={<Navigate to="/search/city" />} />

          <Route element={<Layout />} >
            <Route path="/PoAdresie.html" element={<SearchCityPage postSearchFunction={postSearchFunction} />} />
            <Route path="/PoNumerzeDzialki.html" element={<SearchPrecinctPage postSearchFunction={postSearchFunction} />} />
            <Route path="/IdentyfikatorDzialki.html" element={<SearchLotPage postSearchFunction={postSearchFunction} />} />
          </Route>
          
          <Route path="/search" element={<Layout />}>
            <Route path="city" element={<SearchCityPage postSearchFunction={postSearchFunction} />} />
            <Route path="city/new" element={<Navigate to="/search/city" />} />
            <Route path="lot" element={<SearchLotPage postSearchFunction={postSearchFunction} />} /> 
            <Route path="lot/new" element={<Navigate to="/search/lot" />} /> 
            <Route path="precinct" element={<SearchPrecinctPage postSearchFunction={postSearchFunction} />} />
            <Route path="precinct/new" element={<Navigate to="/search/precinct" />} /> 
          </Route>
        </Routes>
      </BrowserRouter>
      </div>
    </APIConnector>
  );
}

export default App;
