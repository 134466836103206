import { Buffer } from "buffer";



function searchParamsObj(citiesId, street, number = null) {
  return { citiesId, street, number };
}

function constructSearchParamsFromHash(hash) {
  const arr = JSON.parse(Buffer.from(hash, "base64").toString("utf8"));
  return searchParamsObj(arr[0], arr[1], arr[2]);
}


export { searchParamsObj, constructSearchParamsFromHash}