import React from "react";

// collapse icons
// CgListTree AiOutlineUnorderedList IoMdList
import { FiChevronsRight, FiChevronsDown } from "react-icons/fi";
import { ToggleContext } from "../../Toggle/Toggle";


export default function ShowHideUnitsButton({ count, uuid }) {

  const {on} = React.useContext( ToggleContext );

  return (
    <div>
      <a className="searchResult--expand" href={`#${uuid}`}>
        Lokale: {count}
        {on===true ? <FiChevronsDown/> : <FiChevronsRight/> }
      </a>
    </div>
  );
}
