import React from "react";
import { NavLink } from "react-router-dom";

import { FiMapPin, FiChevronsRight } from "react-icons/fi";
import { TbLayoutCollage } from "react-icons/tb";
import { GiPoland } from "react-icons/gi";

export default function Nav({ pageHash, handlePageHashChange }) {
  const menuActiveClass = ({ isActive }) => (isActive ? "item selected active" : "item");

  return (
    <div className="my-override ui top attached tabular menu">
      <NavLink className={menuActiveClass} to="/search/city">
        <FiMapPin /> Po Adresie
      </NavLink>

      <NavLink className={menuActiveClass} to="/search/lot">
        <TbLayoutCollage /> Po Numerze Działki
      </NavLink>

      <div className="right menu">
        <div id="linkG360">
          <a href="https://geoportal360.pl/map" target="_blank">
            <span>Na Mapie </span>
            <GiPoland/>
          </a>
        </div>
      </div>

      {/* <div className="linkG360">
        
      </div> */}
    </div>
  );
}
