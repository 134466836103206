import React from "react";

export default function ResultsMeta({ meta }) {
  const trueLimit = Math.min(meta.found, meta.limit);

  return (
    <span>
      Znaleziono <b>{meta.found}</b> pasujących wyników. Wyświetlono <b>{trueLimit}</b>.
      <br/>
      {meta.limit < meta.found && `Gdy wyników jest zbyt wiele należy doprecyzować wyszukiwanie, 
      np. poprzez podanie numeru budynku.`}
    </span>
  );
}
