import React from "react";
import classNames from "classnames";

import { FiBook } from "react-icons/fi";

import RevealLink from "./Assets/RevealLink";
import IconicStats from "./IconicStats";



export default function CityResultUnit({ data, cityInfo=null, standalone=false }) {
  const classes = {
    'searchResult--grid': true,
    // 'border-top': true,
    // 'pb-3': standalone ? true : false,
    // 'pt-3': standalone ? true : false,
    // 'pb-2': standalone ? false : true,
    // 'pt-2': standalone ? false : true,
    // 'searchResult--blink': standalone ? false : true
  }

  const addressClasses = {
    'searchResult--address': true,
    // 'mt-auto': true,
    // 'mb-auto': true,
    // 'ps-4': standalone ? false : true
  }

  return (
    <div className={classNames( classes )}>

      <div className="searchResult--iconicMeta pb-2">
        <IconicStats stats={data.stat} />
      </div>

      <div className={classNames( addressClasses )}>
        {data.info.street} {data.info.number}, {data.info.city}
        
        {cityInfo && <span className="searchResult--voivodeship">, woj: {cityInfo.descriptor.voivodeship}</span>}
        
      </div>

      <div className="mt-auto mb-auto">
        {!data.stat && <b>lokal {data.info.unit}</b>}
      </div>

      <div className="searchResult--ctaColumn">
        <RevealLink uuid={data.uuid}>
          <button className="searchResult--kwNumber">
            <FiBook />
            &nbsp;{data.canonical}
          </button>
        </RevealLink>
      </div>
    
    </div>
  );
}
