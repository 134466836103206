import React from "react";

import Banner, {BANNER_STYLE} from "../Banner/Banner";

export default function StatusError() {
  return (
    <Banner style={BANNER_STYLE.DANGER} title="Wystąpił błąd wyszukiwania, dlaczego...">
      Najczęściej użytkownicy wpisują nazwę miasta, lecz następnie nie klikają propozycji z
      dynamicznej listy. <br />

      Spróbuj jeszcze raz, lecz gdy zaczniesz wpisywać nazwę miejscowości,&nbsp;
      <b>upewnij się</b>, że klikasz jedną z opcji.
    </Banner>
  );
}
