import request from "superagent";

import { gatewayConfig } from "../config";


function generalCall(descriptor) {
  const url =
    gatewayConfig.BASE_URL + gatewayConfig.SEARCH_PATH + descriptor.path;
  const data = descriptor.params;
  console.log("Sending through api: " + url, data);

  const req =
    descriptor.method === "GET" ? request.get(url) : request.post(url);

  return req.send(data);
}

export const API_METHOD_NAMES = {
  SEARCH_CITY: "SEARCH_CITY",
  SEARCH_LOT: "SEARCH_LOT"
};

const API_METHODS = [];

API_METHODS[ API_METHOD_NAMES.SEARCH_CITY ] = {
  path: "/city",
  params: {
    //only mandatory parameters listed below
    citiesId: null,
    street: null,
    number: null
    //cityText
  },
};

API_METHODS[ API_METHOD_NAMES.SEARCH_PRECINCT ] = {
  path: "/precinct",
  params: {
    citiesId: null,
    lotNumber: null
  }
}

API_METHODS[ API_METHOD_NAMES.SEARCH_LOT ] = {
  path: "/lot",
  params: {
    teritory: null,
    precinct: null,
    number: null
  }
}

export function call(methodName, params) {
  const descr = API_METHODS[methodName];
  descr.params = params;

  return generalCall(descr);
}
