import React from "react";

import * as APIConnector from "../APIConnector";
import ResultsMeta from "./ResultsMeta";

import Banner, {BANNER_STYLE} from "../Banner/Banner";

import StatusLoading from "./StatusLoading";
import StatusError from "./StatusError";



export default function Results({welcomeComponent = null, onlyIf, noResultsWarning, children}) {
  const { connState } = React.useContext(APIConnector.APIConnectorContext);

  
  if (connState.loading) return <StatusLoading />;
  if (connState.error) return <StatusError/>;
  if (connState.data === null || connState.queryAndParams.query !== onlyIf) return welcomeComponent ? welcomeComponent() : null;
  

  //if we are here, there is some data!
  const theData = connState.data.body; 
  
  
  //is data, but no results
  if(connState.done && !theData?.meta?.found)
    return noResultsWarning();


  //rendering data
  return ( 
    <div>
      <Banner style={BANNER_STYLE.SUCCESS} title="Gratulacje! Wyszukiwanie powiodło się.">
        <ResultsMeta meta={theData.meta}/>
      </Banner>

      <h2 className="searchResults--header">Wyniki wyszukiwania:</h2>
      
      {children}
    
    </div>
  );

}
