import React from "react";

import { TbLayoutCollage } from "react-icons/tb";
import { MdOutlineMap } from "react-icons/md";
import { FiBook } from "react-icons/fi";

import GeoLink, { GEOLINK_TYPE } from "./Assets/GeoLink";
import RevealLink from "./Assets/RevealLink";


export default function LotResult({ data, terytInfo }) {

  return (
    <div>

      <div className="searchResultLot item">
        <div className="searchResultLot--geo360">
        
            <GeoLink type={GEOLINK_TYPE.MAP} lotResult={data} target="_blank">
            <div className="searchResultLot--map">
              <img alt={`${data.identifier} na mapie`} src={GEOLINK_TYPE.MAP.img(data)} title="Zobacz na mapie Geoportal360.pl"/>
            </div>
            </GeoLink>

            <GeoLink type={GEOLINK_TYPE.LOT_INTERACTIVE} lotResult={data} className="searchResultLot--mapLink">
              <div>
                <TbLayoutCollage />
              </div>
              <div className="">
                <span>Granice Działki</span>
                <span>
                  <small>Interaktywna Mapa Geoportal360.pl</small>
                </span>
              </div>
            </GeoLink>

            <GeoLink type={GEOLINK_TYPE.PRECINCT_INTERACTIVE} lotResult={data} className="searchResultLot--mapLink">
              <div>
                <MdOutlineMap />
              </div>
              <div>
                <span>Działki w obrębie {data.precinct}</span>
                <span>
                  <small>Mapa obrębu ewidencyjnego</small>
                </span>
              </div>
            </GeoLink>
           
        </div>

        <div className="searchResultLot--contents">

            <div>
                <div>
                  <table className="ui very basic celled compact properties unstackable table"><tbody>
                    <tr>
                      <td>Identyfikator</td>
                      <td>
                        <RevealLink uuid={data.uuid} className="searchResultLot--identifierRevealLink">
                        {data.identifier}
                        <br/>
                        <span>
                          <small>{terytInfo.type} {terytInfo.municipality}</small>
                        </span>
                        </RevealLink>          
                      </td>
                    </tr>
                    <tr><td>Powierzchnia</td><td>{data.size} M<sup>2</sup></td></tr>
                    <tr><td>TERYT</td><td>{data.teritory}</td></tr>
                    <tr><td>Obręb</td><td>{data.precinct}</td></tr>
                    <tr><td>Województwo</td><td>{terytInfo.voivodeship}</td></tr>
                    <tr><td>Powiat</td><td>{terytInfo.county}</td></tr>
                    <tr><td>Gmina</td><td>{terytInfo.municipality}</td></tr>
                    <tr><td>Rodzaj</td><td>{terytInfo.type}</td></tr>
                    <tr>
                      <td>Miejscowość</td>
                      <td>
                        {data.precision !== "within" ? "okolice " : null}
                        {data.info.city}
                      </td>
                    </tr>
                    <tr className="computer-only">
                      <td>Księga wieczysta</td>
                      <td>
                        <RevealLink uuid={data.uuid}>
                        {data.canonical}
                        </RevealLink>
                      </td>
                    </tr>
                  </tbody></table>
                </div>
            </div>
        </div>

        <div className="searchResultLot--ctaColumn">
          <RevealLink uuid={data.uuid}>
            <button className="searchResultLot--kwNumber">
              <FiBook />
              &nbsp;{data.canonical}
            </button>
          </RevealLink>
        </div>


      </div>
    </div>
  );
}
