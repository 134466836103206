import React from "react";
import { BiBuildingHouse } from "react-icons/bi";
import { TbLayoutCollage } from "react-icons/tb";
import { LuDoorClosed } from "react-icons/lu";

export default function IconicStats({ stats }) {
  return stats ? (
    <>

    
      {stats.building && 
      <div className="searchResult--tooltipContainer">
        <BiBuildingHouse />
        <span className="searchResult--count">
          {stats.building ? stats.building : 0}
        </span>
        <div className="searchResult--tooltip">
          budynków: {stats.building ? stats.building : 0}
        </div>
      </div>
      }

      <div className="searchResult--tooltipContainer">
        <TbLayoutCollage />
        <span className="searchResult--count">{stats.lot ? stats.lot : 0}</span>
        <div className="searchResult--tooltip">
          działek: {stats.lot ? stats.lot : 0}
        </div>
      </div>
      
      
      {stats.unit &&
      <div className="searchResult--tooltipContainer">
        <LuDoorClosed />
        <span className="searchResult--count">
          {stats.unit ? stats.unit : 0}
        </span>
        <div className="searchResult--tooltip">
          lokali: {stats.unit ? stats.unit : 0}
        </div>
      </div>}

    </>

  ) : (
    <div className="searchResult--tooltipContainer">
      <LuDoorClosed />
      <div className="searchResult--tooltip">
        Lokal
      </div>
    </div>
  );
}
