import React from "react";
import classNames from "classnames";

import "./banner.css";

import { BsCheckCircleFill, BsFillInfoCircleFill } from "react-icons/bs";
import { PiWarningFill } from "react-icons/pi";
import { BiSolidXCircle } from "react-icons/bi";

const BANNER_STYLE = {
  SUCCESS: {
    class: "banner--success",
    icon: <BsCheckCircleFill/>,
  },
  WARNING: {
    class: "banner--warning",
    icon: <PiWarningFill/>
  },
  NEUTRAL: {
    class: "banner--neutral",
    icon: <BsFillInfoCircleFill/>,
  },
  DANGER: {
    class: "banner--danger",
    icon: <BiSolidXCircle/>,
  },
};
export { BANNER_STYLE };

export default function Banner({
  title = "",
  style = BANNER_STYLE.SUCCESS,
  single = false,
  children,
  className,
}) {
  
  const classes = classNames("banner", style.class, className);

  return (
    <div className={classes}>
      <div className="banner--iconHolder">{style.icon}</div>
      <div className="banner--contentHolder">
        <h6 className="banner--title">{title}</h6>
        <div>{single ? null : children}</div>
      </div>
    </div>
  );
}
