import React from "react";
import { useSearchParams, useHistory } from "react-router-dom";

import "../../css/pages-search-results.css";

import Results from "../Results/Results";
import CityAutocomplete from "../CityAutocomplete";
import * as APIConnector from "../APIConnector";
import { API_METHOD_NAMES } from "../../comms/APIClient";
import CityResults from "../Results/CityResults";
import StatusNoResults from "../Results/StatusNoResults";
import StatusWelcomeCity from "../Results/StatusWelcomeCity";

import {
  searchParamsObj,
  constructSearchParamsFromHash,
} from "../../comms/searchCityHashTools";
import { constructHashFromParams } from "../../comms/constructHashFromParams";

import useQuerySearch from "../../hooks/useQuerySearch";



function NoResultsMessage({responseQuery}) {

  return !responseQuery ? null : (
    <p>
      Brak wyników wyszukiwania dla: <b>{responseQuery.cityInfo.city}</b> (woj. {responseQuery.cityInfo.descriptor.voivodeship}), ulica <b>{responseQuery.street} {responseQuery.number}</b>
    </p>
  )
}



export default function SearchCityPage({postSearchFunction}) {

  const containerRef = React.useRef(null);
  const inputStreetRef = React.useRef(null);
  const inputNumberRef = React.useRef(null);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  let [algoliaState, setAlgoliaState] = React.useState({ query: "" });

  const { connState, initiateAPISearch } = React.useContext(
    APIConnector.APIConnectorContext
  );


  //
  // This useQuerySearch will useEffect in order to
  // IMMEDIATELY perform API search if search query is set.
  //
  useQuerySearch(constructSearchParamsFromHash, API_METHOD_NAMES.SEARCH_CITY);

  //
  // Returning data will propagate the form according to results.
  //
  React.useEffect(() => {

    //its important to set those states only if we have cityInfo, and we have it from the right API response
    if (connState?.data?.body?.query?.cityInfo && connState.queryAndParams.query === API_METHOD_NAMES.SEARCH_CITY) {
      const query = connState.data.body.query;
      
      //setting algolia state
      setAlgoliaState({
        citiesId: query.cityInfo.id,
        query: query.cityInfo.city,
        activeItemId: query.cityInfo.id,
      });

      //setting form state
      inputStreetRef.current.value = query.street || "";
      inputNumberRef.current.value = query.number || "";

      //setting URL search params
      setUrlSearchParams({
        search: constructHashFromParams(
          query.cityInfo.id,
          query.street,
          query.number
        ),
      }); 

      postSearchFunction?.();
    }
  }, [connState]);



  const handleSubmit = (event) => {
    event.preventDefault();

    const query =
      document.querySelector("#cityAutocompleteId-input")?.value ||
      document.querySelector("div.aa-DetachedSearchButtonQuery")?.innerHTML;

    const params = searchParamsObj(
      algoliaState.citiesId,
      inputStreetRef.current.value,
      inputNumberRef.current.value
    );

    //if algolia has been touched after autocomplete
    //then we ignore citiesId and send just cityText
    if ( query && query != algoliaState.query ) {
      params.cityText = query;
      params.citiesId = null;
    }

    setAlgoliaState((prev) => {
      return { ...prev, query: query || algoliaState.query };
    }); //prevents resetting autosuggest
    initiateAPISearch({
      params,
      query: API_METHOD_NAMES.SEARCH_CITY,
    }); 

  };



  return (
    <div className="searchPageContainer" ref={containerRef}>
      <h1>Szukaj Według Adresu</h1>
      <form onSubmit={handleSubmit}>
        <div className="searchBox searchCity">
          <div className="searchBox--city">

            <CityAutocomplete
              selectedState={algoliaState}
              handleSelect={(event) => {

                setAlgoliaState({
                  citiesId: event.item.id,
                  query: event.item.city,
                  activeItemId: event.item.objectID,
                });

                inputStreetRef.current?.focus();
              }}
            />
          </div>

          <div className="ui input searchBox--street">
            <input
              ref={inputStreetRef}
              className=""
              id="inputStreet"
              type="text"
              placeholder="Ulica (bez numeru)"
            />
          </div>

          <div className="ui input searchBox--number">
            <input
              ref={inputNumberRef}
              className=""
              id="inputNumber"
              name="inputNumber"
              type="text"
              placeholder="Numer (opcja)"
            />
          </div>

          <div className="searchBox--cta">
            <button className="ui yellow large button fluid">
              Szukaj
            </button>
          </div>
        </div>
      </form>

      <Results
        onlyIf={API_METHOD_NAMES.SEARCH_CITY}
        welcomeComponent={StatusWelcomeCity}
        noResultsWarning={() => (
          <StatusNoResults title={<NoResultsMessage responseQuery={connState?.data?.body?.query} />} >
            <b>Co możesz zrobić?</b>
            <ol>
              <li>
                Upewnij się, że kliknąłeś(aś) miejscowość z zaproponowanej
                listy. Lista rozwinie się gdy zaczniesz pisać.
              </li>
              <li>
                Sprawdź czy podałeś <b>prawidłową nazwę</b> ulicy i numer
                budynku.
              </li>
              <li>
                W przypadku niewielkich ulic, możesz spróbować nie podawać
                numeru.
              </li>
              <li>
                Dla <b>bardzo małych miejscowości</b>, możesz nie podawać nazwy
                ulicy.
              </li>
            </ol>
          </StatusNoResults>
        )}
      >
        <CityResults />
      </Results>
    </div>
  );
}
