import React from "react";
import {Link} from "react-router-dom";

import Banner, { BANNER_STYLE } from "../Banner/Banner";

export default function StatusWelcomeLot() {
  return (
    <Banner
      style={BANNER_STYLE.NEUTRAL}
      title="Krótko o wyszukiwarce wg. numeru działki i miejscowości"
    >
      <b>Jak rozpocząć:</b>
      <ul>
        <li>
          Zacznij pisać nazwę miejscowości, a następnie <b>wybierz z listy</b> propozycji.
        </li>
        <li>
          W drugim polu wpisz krótki numer działki <b>np: 123/6</b>
        </li>
        <li>
          Jeśli posiadasz pełny identyfikator działki w formie np: <code>121602_2.0005.269/2</code>,
          to przejdź <Link to="/IdentyfikatorDzialki.html" relative="path">TUTAJ</Link>.
        </li>
      </ul>
    </Banner>
  );
}
