export const algoliaConfig = {
  APPID: "8TJJNGMFWP",
  READ_APIKEY: 'ea34c66d9e83ea19102a1e6505ca0ae4',
  INDEX: 'cities',
}

export const algoliaProxyConfig = process.env.REACT_APP_USE_ALGOLIA_PROXY === "yes" ? 
  {
      hosts: [{ url:process.env.REACT_APP_ALGOLIA_PROXY_URL,
      protocol: process.env.REACT_APP_ALGOLIA_PROXY_PROTOCOL}] 
    }
  : null;

export const gatewayConfig = {
  BASE_URL: process.env.REACT_APP_API_URL,
  SEARCH_PATH: "/api/v1/search",
  GENERIC_PATH: "/api/v1"
}

console.log("Using API url: ", gatewayConfig.BASE_URL);
console.log("Using Suggest Proxy: ",process.env.REACT_APP_USE_ALGOLIA_PROXY);
if( process.env.REACT_APP_USE_ALGOLIA_PROXY === 'yes' )
  console.log("Config:", algoliaProxyConfig);