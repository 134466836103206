import React from "react";

import * as APIConnector from "../APIConnector";
import LotResult from "./LotResult";

import { gatewayConfig } from "../../config";

function emptyTerytInfo() {
  return {
    voivodeship: null,
    county: null,
    municipality: null,
    type: null
  }
}

export default function LotResults() {
  const [terytInfo, setTerytInfo] = React.useState( emptyTerytInfo );
  
  const { connState } = React.useContext(APIConnector.APIConnectorContext);
  
  React.useEffect( () => {


    //this teryt info fetching sideeffect, is somehow separate from our Search API
    //both in terms of server api routes and in terms of how and when we fetch it client 
    //side, now APIConnector is used here
    //Lets think how to unify it in the future. If unify.

    async function fetchTerytInfo( data ) {
      fetch( gatewayConfig.BASE_URL + gatewayConfig.GENERIC_PATH +'/teryt/'+ data.body.data[0].teritory)
        .then( res => res.json() )
        .then( data => {
          setTerytInfo( data ); 
      })
    }    

    if( connState.data ) fetchTerytInfo( connState.data );
  }, [connState])
  
  if( connState.data === null ) return;

  const theData = connState.data.body;


  return (
    <div id="results" className="ui divided relaxed items padded raised">
      {theData.data.map(
        (entry, index) => <LotResult key={index} data={entry} terytInfo={terytInfo} />
      )}
    </div> 
  )

}