import React from "react";

export default function StatusLoading() {

  return (

    <div className="ui segment" style={{height: "80px"}}>
      <div className="ui active inverted dimmer">
        <div className="ui text loader">Trwa Wyszukiwanie</div>
      </div>
      <p></p>
    </div>

  )
}
