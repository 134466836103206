import React from "react";

import * as APIConnector from "../APIConnector";
import CityResultAggregate from "./CityResultAggregate";
import CityResultUnit from "./CityResultUnit";

export default function CityResults() {

  const { connState } = React.useContext(APIConnector.APIConnectorContext);
  if( connState.data === null ) return;


  const realData = connState.data.body;


  return (
    <div id="results">
      {realData.data.map(
        (entry, index) => entry.children && entry.children.length > 0 ? (
          <CityResultAggregate key={index} data={entry}  cityInfo={realData.query.cityInfo} />
        ) : (
          <CityResultUnit key={index} data={entry} standalone={true} cityInfo={realData.query.cityInfo} />
        )
      )}
    </div> 
  )

}