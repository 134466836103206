import React from "react";
import {Link} from "react-router-dom";

import Banner, { BANNER_STYLE } from "../Banner/Banner";

export default function StatusWelcomeLot() {
  return (
    <Banner
      style={BANNER_STYLE.NEUTRAL}
      title="Krótko o wyszukiwarce wg. identyfikatora działki..."
    >
      <b>Jak rozpocząć:</b>
      <ul>
        <li>
          Musisz podać dokładny identyfikator działki,{" "}
          <b>np: 121602_2.0005.269/2</b>
        </li>
        <li>
          Powyższa forma rozdzielona kropkami oznacza:{" "}
          <code>TERYT.OBRĘB.DZIAŁKA</code> .
        </li>
        <li>
          Jeśli znasz tylko ostatni fragment tj. <code>DZIAŁKA</code>,
          kliknij <Link to="/PoNumerzeDzialki.html" relative="path">TUTAJ &raquo;</Link>.
        </li>
      </ul>
    </Banner>
  );
}
