import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import * as APIConnector from "../APIConnector";
import { API_METHOD_NAMES } from "../../comms/APIClient";
import Results from "../Results/Results";
import LotResults from "../Results/LotResults";
import StatusWelcomeLot from "../Results/StatusWelcomeLot";
import StatusNoResults from "../Results/StatusNoResults";

import {
  searchParamsObj,
  constructSearchParamsFromHash,
} from "../../comms/searchLotHashTools";
import { constructHashFromParams } from "../../comms/constructHashFromParams";
import useQuerySearch from "../../hooks/useQuerySearch";



function validatePrecinct(string) {
  return /[0-9]{6}_[0-9]{1}\.[0-9]{4}\..*/gi.test(string.trim());
}



function NoResultsMessage({responseQuery}) {

  return !responseQuery ? null : (
    <p>
      Brak wyników wyszukiwania dla identyfikatora: <b>{responseQuery.teritory}.{responseQuery.precinct}.{responseQuery.number}</b>
    </p>
  )
}



export default function SearchLotPage({postSearchFunction}) {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const { connState, initiateAPISearch } = React.useContext(
    APIConnector.APIConnectorContext
  );

  const formik = useFormik({
    initialValues: {
      inputLotId: "",
    },

    validate: (values) => {
      let errors = {};
      if (values.inputLotId === "")
        errors.inputLotId = "Musisz wpisać pełny numer działki.";
      else if (values.inputLotId.length < 15)
        errors.inputLotId =
          "Numer działki musi mieć długość conajmniej 15 znaków.";
      else if (!validatePrecinct(values.inputLotId))
        errors.inputLotId =
          "Podany numer nie jest w ogónie przyjętym formacie.";

      return errors;
    },

    onSubmit: (values) => {
      const lotId = values.inputLotId.trim();

      initiateAPISearch({
        query: API_METHOD_NAMES.SEARCH_LOT,
        params: searchParamsObj( lotId ),
      });

      setUrlSearchParams( {search: constructHashFromParams(lotId) }); 

      
    },
  });


  //
  // This useQuerySearch will useEffect in order to
  // IMMEDIATELY perform search if search query is set.
  //
  useQuerySearch(constructSearchParamsFromHash, API_METHOD_NAMES.SEARCH_LOT);

  

  return (
    <div className="searchPageContainer">
      <h1>
        Szukaj Według Działki (identyfikatora)
        <sub>
          <Link to="/PoNumerzeDzialki.html" relative="path">
            zmień...
          </Link>
        </sub>
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="searchBox searchLot">
          <div className="ui input searchBox--lotId">
            <input
              type="text"
              className=""
              id="inputLotId"
              name="inputLotId"
              placeholder="Identyfikator działki"
              value={formik.values.inputLotId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="searchBox--cta text-center">
            <button
              className="ui large yellow button fluid"
              disabled={formik.errors.inputLotId}
            >
              Szukaj
            </button>
          </div>
        </div>
        {formik.touched.inputLotId && formik.errors.inputLotId ? (
          <div className="searchBox--validationError">
            {formik.errors.inputLotId}
          </div>
        ) : null}
      </form>

      <Results
        onlyIf={API_METHOD_NAMES.SEARCH_LOT}
        welcomeComponent={StatusWelcomeLot} noResultsWarning={ () => (
          <StatusNoResults title={<NoResultsMessage responseQuery={connState?.data?.body?.query} />}>
            <b>Co możesz zrobić?</b>
            <ol>
              <li>Upewnij się, wpisałeś prawidłowy numer działki w formacie <code>TERYT.OBRĘB.DZIAŁKA</code> .</li>
              <li>Przykład prawidłowego identyfikatora działki: <b>121602_2.0005.269/2</b> </li>
              <li>Jeśli nie posiadasz identyfikatora, zapraszamy do wyszukiwania na mapie na stronie <a target="_blank" href="https://geoportal360.pl/map/">Geoportal360</a> .</li>
            </ol>
          </StatusNoResults>
        )}>
        <LotResults />
      </Results>
    </div>
  );
}
