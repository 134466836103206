import React from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import * as APIConnector from "../APIConnector";
import { API_METHOD_NAMES } from "../../comms/APIClient";
import Results from "../Results/Results";
import LotResults from "../Results/LotResults";
import StatusWelcomePrecinct from "../Results/StatusWelcomePrecinct";
import StatusNoResults from "../Results/StatusNoResults";
import CityAutocomplete from "../CityAutocomplete";
import useQuerySearch from "../../hooks/useQuerySearch";

import {
  searchParamsObj,
  constructSearchParamsFromHash,
} from "../../comms/searchPrecinctHashTools";
import { constructHashFromParams } from "../../comms/constructHashFromParams";

const pagesPath = "/search/precinct";

function NoResultsMessage({ responseQuery }) {
  return !responseQuery ? null : (
    <p>
      Brak wyników wyszukiwania dla: <b>{responseQuery.cityInfo.city}</b> (woj.{" "}
      {responseQuery.cityInfo.descriptor.voivodeship}), działka nr{" "}
      <b>{responseQuery.lotNumber}</b>
    </p>
  );
}

export default function SearchPrecinctPage({ postSearchFunction }) {
  const containerRef = React.useRef(null);
  const inputLotNumberRef = React.useRef(null);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  let [algoliaState, setAlgoliaState] = React.useState({ query: "" });

  const { connState, initiateAPISearch } = React.useContext(
    APIConnector.APIConnectorContext
  );

  //
  // Perform immediate API search if 'search' query is set.
  //
  useQuerySearch(
    constructSearchParamsFromHash,
    API_METHOD_NAMES.SEARCH_PRECINCT
  );

  //
  // Set some states when API result arrives
  //
  React.useEffect(() => {
    if (
      connState?.data?.body?.query?.cityInfo &&
      connState.queryAndParams.query === API_METHOD_NAMES.SEARCH_PRECINCT
    ) {
      const query = connState.data.body.query;

      //setting algolia state
      setAlgoliaState({
        citiesId: query.cityInfo.id,
        query: query.cityInfo.city,
        activeItemId: query.cityInfo.id,
      });

      //setting form state
      inputLotNumberRef.current.value = query.lotNumber || "";

      //setting URL search params
      setUrlSearchParams({
        search: constructHashFromParams(query.cityInfo.id, query.lotNumber),
      });

      postSearchFunction?.();
    }
  }, [connState]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const query =
      document.querySelector("#cityAutocompleteId-input")?.value ||
      document.querySelector("div.aa-DetachedSearchButtonQuery")?.innerHTML;
      
    setAlgoliaState((prev) => {
      return { ...prev, query };
    }); //prevents resetting autosuggest

    //validation
    if (inputLotNumberRef.current?.value.trim() === "") return;

    const params = searchParamsObj(
      algoliaState.citiesId,
      inputLotNumberRef.current.value
    );

    //if algolia has been touched after autocomplete
    //then we ignore citiesId and send just cityText
    if (query !== algoliaState.query) {
      params.cityText = query;
      params.citiesId = null;
    }

    setAlgoliaState((prev) => {
      return { ...prev, query };
    }); //prevents resetting autosuggest
    initiateAPISearch({
      query: API_METHOD_NAMES.SEARCH_PRECINCT,
      params: params,
    });
  };

  return (
    <div className="searchPageContainer" ref={containerRef}>
      <h1>
        Szukaj Według Nr Działki <span>(i miejscowości)</span>
        <sub>
          <Link to="/IdentyfikatorDzialki.html" relative="path">
            zmień...
          </Link>
        </sub>
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="searchBox searchPrecinct">
          <div className="searchBoxPrecinct--city">
            <CityAutocomplete
              selectedState={algoliaState}
              handleSelect={(event) => {
                setAlgoliaState({
                  citiesId: event.item.id,
                  query: event.item.city,
                  activeItemId: event.item.objectID,
                });

                containerRef.current.querySelector("#inputLotNumber").focus();
              }}
            />
          </div>

          <div className="searchBoxPrecinct--number ui input">
            <input
              ref={inputLotNumberRef}
              className=""
              id="inputLotNumber"
              name="inputLotNumber"
              type="text"
              placeholder="Działka, np: 123/6"
            />
          </div>

          <div className="searchBox--cta text-center">
            <button className="ui yellow large button fluid">Szukaj</button>
          </div>
        </div>
        {inputLotNumberRef.current?.value.trim() === "" && (
          <div className="searchBox--validationError">
            Musisz jeszcze podać numer działki.
          </div>
        )}
      </form>

      <Results
        onlyIf={API_METHOD_NAMES.SEARCH_PRECINCT}
        welcomeComponent={StatusWelcomePrecinct}
        noResultsWarning={() => (
          <StatusNoResults
            title={
              <NoResultsMessage responseQuery={connState?.data?.body?.query} />
            }
          >
            <b>Co możesz zrobić?</b>
            <ul>
              <li>
                Upewnij się, że kliknąłeś(aś) miejscowość z zaproponowanej
                listy. Lista ukaże się gdy zaczniesz pisać.
              </li>
              <li>
                Sprawdź czy podałeś(aś) prawidłowy, <b>krótki</b> numer działki
                np: <b>123/5</b>.
              </li>
              <li>
                Jeśli posiadasz długi identyfikator działki w formie np:{" "}
                <code>121602_2.0005.269/2</code>, to przejdź{" "}
                <Link to="/IdentyfikatorDzialki.html" relative="path">
                  TUTAJ
                </Link>
                .
              </li>
            </ul>
          </StatusNoResults>
        )}
      >
        <LotResults />
      </Results>
    </div>
  );
}
