import React from "react";
import algoliasearch from "algoliasearch";
import Autocomplete from "./Autocomplete";
import AutocompleteItem from "./AutocompleteItem";
import { getAlgoliaResults } from "@algolia/autocomplete-js";

import { algoliaConfig, algoliaProxyConfig } from "../config";


const searchClient = algoliasearch(
  algoliaConfig.APPID,
  algoliaConfig.READ_APIKEY,
  algoliaProxyConfig
);

function CityAutocomplete({ handleSelect, handleBlur, selectedState }) {
  return (
    <Autocomplete
      id="cityAutocompleteId"
      openOnFocus={true}
      placeholder="Miejscowość... zacznij pisać"
      translations={{
        // clearButtonTitle: string, // defaults to 'Clear'
        // submitButtonTitle: string, // defaults to 'Submit'
        detachedCancelButtonText: "Wymaż", // defaults to 'Cancel'
      }}
      initialState={selectedState}
      getSources={({ query }) => [
        {
          sourceId: "cities",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: algoliaConfig.INDEX,
                  query,
                },
              ],
            });
          },
          onSelect: (event) => {
            handleSelect(event);
            console.log("onSelect: ", event);
          },
          onStateChange: (event) => {
            console.log("onStateChange", event);
          },
          getItemInputValue({ item }) {
            return item.city;
          },
          templates: {
            item({ item, components }) {
              return (
                <AutocompleteItem
                  hit={item}
                  attribute="city"
                  components={components}
                />
              );
            },
            header: () => {
              return (
                <small>
                  Zacznij pisać, a potem <b>koniecznie wybierz</b> z listy.
                </small>
              );
            },
            noResults() {
              return "Brak miejscowości, sprawdź pisownię";
            },
          },
        },
      ]}
    />
  );
}

export default CityAutocomplete;
