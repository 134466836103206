import React from 'react';

export default function AutocompleteItem( {hit, components, handleClicked}) {
  return ( 
    <div className="aa-ItemContent">
      <div className="aa-ItenTitle">
        <components.Highlight hit={hit} attribute="city" />
        <small> 
          &nbsp;{hit.descriptor.municipality!==undefined && hit.descriptor.municipality!==hit.city ? `${hit.descriptor.municipality}, ` : ''} 
          &nbsp;{hit.descriptor.county},
          &nbsp;woj. {hit.descriptor.voivodeship}
        </small>
      </div>
    </div>
   );
}
