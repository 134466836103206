import { Buffer } from "buffer";



function searchParamsObj(fullLotId) {
  const split = fullLotId.split(".");

  return {
    teritory: split[0],
    precinct: split[1],
    number: split[2],
  };
}



function constructSearchParamsFromHash(hash) {
  const arr = JSON.parse(Buffer.from(hash, "base64").toString("utf8"));
  return searchParamsObj(arr[0]);
}

export { searchParamsObj, constructSearchParamsFromHash };
