import React from "react";


const GEOLINK_TYPE = {
  MAP: {
    href: (res) => `https://geoportal360.pl/map/#m:geoparcels,${res.identifier}`,
    img: (res) => `https://static.geoportal360.pl/kwp/s/parcels/${res.teritory}/${res.precinct}/${res.identifier.split('.')[2].replace('/',':')}.jpeg`,
    title: (res) => "Zobacz na mapie Geoportal360.pl"
  },
  LOT_INTERACTIVE: {
    href: (res) => `https://geoportal360.pl/map/#m:geoparcels,${res.identifier}`,
    title: (res) => { return "Zobacz na mapie Geoportal360.pl"}
  },
  PRECINCT_INTERACTIVE: {
    href: (res) => `https://geoportal360.pl/map/#m:geoprecincts,${res.teritory}.${res.precinct}`,
    title: (res) => "Zobacz na mapie Geoportal360.pl"
  },
}
export {GEOLINK_TYPE};


export default function GeoLink( {type=GEOLINK_TYPE.MAP, target="_blank", className=null, lotResult, children} ) {

  return (
    <a href={type.href(lotResult)} title={type.title(lotResult)} className={className} target={target}>{children}</a>
  )

} 