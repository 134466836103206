import { Buffer } from "buffer";


function constructHashFromParams( ...args ) {
  const arr = [ ...arguments ];
  const jsonStr = JSON.stringify(arr);
  const base64Str = Buffer.from(jsonStr, "utf8").toString("base64");
  return base64Str;
}


export {constructHashFromParams}